import React from "react"
import Layout from "../../components/layout"

import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
const Products = loadable(() =>
  pMinDelay(import("../../components/peaksuiteProducts"), 500)
)
// import docgen from "../../images/DocgenpeakSUITE.mp4"

const Banner = () => (
  <div>
    <div className="hero-banner peaksuite-wrap-hero">
      <div className="container">
        <div
          className="hero-banner-content text-center"
          style={{ padding: "50px 0px" }}
        >
          <div
            className="hero-title-wrap text-center"
            style={{ background: "#fff" }}
          >
            <ScrollAnimation animateIn="fadeInLeft" initiallyVisible={true}>
              <Link aria-label="link" to="/peaksuite/peaksuite-calendar/">
                <StaticImage
                  imgClassName="img-fluid"
                  src="../../../static/assets/peaksuite-Calendar.png"
                  alt="peakSUITE CALENDAR"
                  width={400}
                  layout="constrained"
                  priority
                />
              </Link>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  </div>
)
class ProductPeakSuite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "peakSuite | basecamp",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    localStorage.setItem("title", this.state.title)
  }
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="peakSUITE Calendar | Custom Add-Ons for Quickbase"
          description="This custom app gives your team access to a custom calendar system created in Quickbase that automatically syncs to the correct systems. Learn more."
        />

        <div className="page-content pt-0" style={{ background: "#f8f8f8" }}>
          <section className="component pt-4 pb-0">
            <div className="container">
              <div className="row mb-5">
                <div className="col-md-12 peakSuite-product">
                  <div className="row">
                    <div className="col-md-7 col-sm-12">
                      <iframe
                        src="https://drive.google.com/file/d/1rrLy3G063NZ3Ns5ujWHEsvD3FdqHRXN-/preview"
                        frameBorder="0"
                        width="100%"
                        height="400"
                        className="mr-3 mb-3"
                        style={{
                          boxShadow: "0 8px 10px rgba(0, 0, 0, 0.37)",
                        }}
                        title="basecamp"
                      />
                    </div>
                    <div className="col-md-5 col-sm-12">
                      <p>
                        <span className="basecamp-color">
                          peakSUITE | Calendar{" "}
                        </span>
                        Access a calendar system created in Quickbase that fits
                        your needs and works for you. Simply click on an event
                        and edit it directly on the page without leaving the
                        platform or worrying about syncing to the correct
                        systems.
                      </p>
                      <p>
                        Merge multiple data sources and confidently integrate a
                        range of robust capabilities into your workflows without
                        the major overhaul. Plus, you can easily customize your
                        Calendar to reflect your brand.
                      </p>
                      <p>
                        Best of all, Calendar and Chat Tool work together.
                        Create, update, delete, and filter events or send
                        messages to your organization. All within seconds. All
                        from the same page.
                      </p>
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <Link
                      to="/contact/"
                      onClick={this.handleSubmit}
                      className="btn btn-primary btn-arrow echo-bg"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row peaksuite-products mt-5">
                <div className="col-lg-2 col-md-3 col-12 align-self-center  prod-order-2">
                  {" "}
                  <div className="return-btn-basecamp">
                    <Link
                      to="/peaksuite/"
                      className="btn btn-primary-outline px-3"
                      style={{
                        color: "#224a9a",
                        border: "1px solid #224a9a",
                        fontSize: "18px",
                      }}
                    >
                      <i className="fa fa-caret-left mr-2" />
                      Return
                    </Link>
                  </div>
                </div>
                <div className="col-lg-10 col-md-9 col-12 prod-order-1">
                  <Products />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ProductPeakSuite
